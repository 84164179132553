import { Button, Video, Image, ModuleContainer, Text, AlignContent } from 'ui';
import { useTheme } from '@emotion/react';
import NextLink from 'next/link';
import React from 'react';
import { queries } from '~/theme/breakpoints';
import { useModule } from '~/shared/hooks/useModule';
import { CmsModel } from 'api-types';
import styles from './M10Hero.module.css';
import clsx from 'clsx';
import { getTextColor } from 'utils'

export type M10HeroProps = CmsModel.M010HeroModule;

export const M10Hero = ({
    image,
    video,
    headline,
    subHeadline,
    callToAction,
    heroHeight,
    mediaLayout = 'full',
    backgroundColor = '#ffffff',

    ...rest
}: M10HeroProps) => {
    const { index } = useModule();
    const { sizes } = useTheme();

    const shouldDisplayImage = video && video.length > 0 ? false : true;

    const imageSizes = {
        full: `${queries.xs} 100vw, ${queries.pageMax} 100vw, ${sizes.pageMaxWidth}px`,
        right: `${queries.xs} 100vw, ${queries.pageMax} 50vw, ${sizes.pageMaxWidth / 2}px`,
        left: `${queries.xs} 100vw, ${queries.pageMax} 50vw, ${sizes.pageMaxWidth / 2}px`,
    };

    const m10Wrapper = clsx(styles.m10Wrapper, styles[`${heroHeight}Height`]);
    const m10Content = clsx(
        styles.m10Content,
        styles[`${mediaLayout}Media`],
        {
            [styles.splitMedia]: mediaLayout !== 'full',
            [styles.left]: mediaLayout == 'left',
        }
    );

    return (
        <ModuleContainer  {...rest} hasPadding={false} isHero={true} >
            <div className={styles.heroPadding} style={{ backgroundColor: mediaLayout == 'full' ? '#ffffff' : backgroundColor }}>
                <div
                    className={m10Wrapper}
                    style={{ maxWidth: sizes.pageMaxWidth }}

                >
                    <div className={m10Content}>
                        <AlignContent
                            alignment='left'
                            position='center'
                            textColor={getTextColor()}


                        >
                            {headline ? (
                                <Text
                                    className={mediaLayout == 'full' ? styles.headlineWidth : styles.fullWidth}
                                    as={index === 1 ? 'h1' : 'h2'}
                                    variant='display2'
                                    color={backgroundColor !== ('#ffffff' || '#E4E9F2') ? 'neutralWhite' : 'bodyColor'}
                                >
                                    {headline}
                                </Text>
                            ) : null}
                            {subHeadline ? (
                                <Text
                                    className={mediaLayout == 'full' ? styles.subHeadlineWidth : styles.fullWidth}
                                    as='span'
                                    color={backgroundColor !== ('#ffffff' || '#E4E9F2') ? 'neutralWhite' : 'bodyColor'}
                                >
                                    {subHeadline}
                                </Text>
                            ) : null}
                            {callToAction ? (
                                <NextLink href={callToAction.url} passHref>
                                    <Button
                                        variant='secondary'
                                    >
                                        {callToAction?.title}
                                    </Button>
                                </NextLink>
                            ) : null}
                        </AlignContent>
                        {image || video ? (
                            <div className={clsx(styles.mediaWrapper, {
                                [styles.videoAspect]: !shouldDisplayImage && video
                            })}>
                                {shouldDisplayImage && image ? (
                                    <Image
                                        src={image.src}
                                        alt={image.alt}
                                        layout="fill"
                                        objectFit="cover"
                                        sizes={imageSizes[mediaLayout]}
                                        priority={index < 2}
                                    />
                                ) : null}
                                {!shouldDisplayImage && video ? (
                                    <Video
                                        muted={false}
                                        controls={true}
                                        src={video}
                                        posterSrc={image?.src}
                                        cover={true}
                                        hasMaxHeight={true}
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </ModuleContainer >
    );
};
