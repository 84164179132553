import { useTheme } from '@emotion/react';
import { readableColorIsBlack } from 'color2k';
import { useMemo } from 'react';
import { ButtonDeprecatedProps } from 'ui';
import { getTextColor, usePotentialThemeColor } from '$theme';

type useModuleThemeProps = {
    backgroundColor?: string;
};

export const useModuleTheme = ({ backgroundColor }: useModuleThemeProps) => {
    const { colors } = useTheme();

    const backgroundColorValue = usePotentialThemeColor(backgroundColor || '');

    // Get text color from background color if not set
    const textColor = useMemo(() => {
        // Fallback to background color
        return getTextColor(backgroundColorValue || colors.white);
    }, [backgroundColorValue]);

    // Get button shade based on background color
    const buttonShade = useMemo<Exclude<ButtonDeprecatedProps['shade'], undefined>>(() => {
        return readableColorIsBlack(backgroundColorValue || colors.white) ? 'dark' : 'light';
    }, [backgroundColorValue]);

    return {
        backgroundColor: backgroundColorValue,
        textColor,
        buttonShade,
    };
};
